import { createSlice } from "@reduxjs/toolkit"

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    sidebarShow: true,
    sidebarUnfoldable: false,
  },
  reducers: {
    toggleShow: state => {
      state.sidebarShow = !state.sidebarShow
    },
    toggleUnfoldable: state => {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    saveShow: (state, action) => {
      const { payload } = action;
      state.sidebarShow = state.sidebarShow, payload
    }
  }
})

export const { toggleShow, toggleUnfoldable, saveShow } = sidebarSlice.actions

export default sidebarSlice.reducer