import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { NavContext } from 'src/helpers/NavContext';
import { ClientContext } from 'src/helpers/ClientContext';
import { AuthContext } from 'src/helpers/AuthContext';
import { DisableActionContext } from 'src/helpers/DisableActionContext';
import { FiltersContext } from 'src/helpers/FiltersContext';
import { PermissionContext } from 'src/helpers/PermissionContext'
import { useState } from 'react';
import axios from 'axios';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/user/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const [authState, setAuthState] = useState({
    username: '', 
    id: 0, 
    status: false,
    basic: false,
    standard: false,
    admin: false,
    superadmin: false,
    techadmin: false,
    viewFinancials: false,
    enableUserFilter: false,
    limitOwnData: true,
    role: '',
    idClient: null
  });

  const [client, setClient] = useState({
    id: null,
    name: 'Select Client',
    img: '',
  });

  const [permissions, setPermissions] = useState([{
    idRights: 0,
    component: '',
    basic: false,
    standard: false,
    admin: false,
    superadmin: false,
    techadmin: false,
  }]);

  // FILTERS CONTEXT
  let lastMonday = new Date();
  lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() + 6) % 7);
  const twoWorkWeeksAgo = new Date(new Date().setDate(lastMonday.getDate()-14));

  const [filters, setFilters] = useState({
    startDate: new Date(twoWorkWeeksAgo.setHours(0, 0, 0, 0)),
    endDate: new Date(lastMonday.setHours(23, 59, 59, 59)),
    location: [], locationName: [], itemStatus: [], itemStatusName: [], resource: [], resourceName: [], service: [], serviceName: [], age: [], ageName: [], itemType: [], itemTypeName: [], result: [], resultName: [], user: [], userName: [], activity: []
  });
  // 

  const [nav, setNav] = useState(sessionStorage.getItem('Nav Data') !== null ? { nav: sessionStorage.getItem('Nav Data'), alt: 0 } : { nav: 0, alt: 0 })
  const [disableAction, setDisableAction] = useState(false);

  useEffect(() => {
    nav.id === 1 && onRefresh();
    // TODO: This is most likely not needed anymore but removing requires testing.
    nav.nav === 1 && sessionStorage.setItem('Nav Data', JSON.stringify( nav ))
    nav.nav === 0 && sessionStorage.setItem('Nav Data', JSON.stringify( nav ))
    
    axios.get(`${process.env.REACT_APP_CLIENT}/auth/validate`, { 
      headers: {
        'x-access-token': sessionStorage.getItem('access-token'),
      },
    }).then((response) => {
      if (response.data.error) {
        setAuthState({...authState, status: false});
      } else {
        setAuthState({
          username: response.data.username, 
          id: response.data.id, 
          status: true,
          basic: response.data.basic,
          standard: response.data.standard,
          admin: response.data.admin,
          superadmin: response.data.superadmin,
          techadmin: response.data.techadmin,
          viewFinancials: response.data.viewFinancials,
          enableUserFilter: response.data.enableUserFilter,
          limitOwnData: response.data.limitOwnData,
          role: response.data.role,
          idClient: response.data.idClient,
        });
      }
    });
  }, [nav]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_CLIENT}/rights`)
      .then((response) => {
        if (response.data.error) {
        } else {
          setPermissions(response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching permissions:', error);
      });
  }, [authState.role, authState.id]);
  
  return (
    <AuthContext.Provider value={{authState, setAuthState}}>
      <ClientContext.Provider value={{client, setClient}}>
        <NavContext.Provider value={{nav, setNav}}>
          <PermissionContext.Provider value={{permissions, setPermissions}}>
            <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  {!authState.status ? (
                  <>
                    <Route path="/" name="Login Page" render={(props) => <Login {...props} />} />
                  </>
                  ) : ( 
                    <>
                      {/* ALL CONTEXTS */}
                      <DisableActionContext.Provider value={{disableAction, setDisableAction}}>
                        <FiltersContext.Provider value={{filters, setFilters}}>
                          <div className={disableAction ? "content-pointer-event-none" : "path" }>
                            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                          </div>
                        </FiltersContext.Provider>
                      </DisableActionContext.Provider>
                      <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                      <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                    </>
                  )}
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </PermissionContext.Provider>
        </NavContext.Provider>
      </ClientContext.Provider>
    </AuthContext.Provider>
  )
}

export default App;
